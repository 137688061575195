@import "../../../mixins.module.scss";

//Steckbrief

@mixin steckbriefChip {
    background-color: #e2e2e2;
    border-radius: 20px;
    padding: 2px 20px;
    font-size: 0.85rem;
    display: inline-block;
    margin-right: 5px;
    height: 18px;
    font-weight: 600;
}

.themeTagsWrapper {
    display: block;
    .themeChip {
        background-color: grey;
        color: #000;
        font-weight: bold;
        @include steckbriefChip;
    }
}

.mainContentWrapper {
    @include sectionBoxStyles;
    @include mainContentSectionStyles;
    flex-grow: 1;
    padding: 0px;
}

.modalTitleWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    svg {
        color: #8e8e8e;
    }
    h3 {
        margin: 0px;
    }
}

.modalTitle {
    display: flex;
    // justify-content: center;
    align-items: center;
    svg {
        font-size: 1.3rem;
        margin-right: 10px;
        margin-top: 3px;
    }
}

.profileInfoWrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    font-size: 0.95rem;
    .column {
        width: 45%;
    }
}

.valueWrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 5px;
    p {
        margin: 5px 0px;
        width: 50%;
    }
    .valueTitle {
        font-weight: bold;
        // width: 200px;
    }
    p:nth-child(2) {
        text-align: right;
    }
    .priorityChip {
        @include steckbriefChip;
        color: #fff;
    }
}

.shortDescWrapper {
    display: flex;
    flex-direction: column;
    padding: 5px;
    justify-content: space-between;

    .valueTitle {
        font-weight: bold;
        width: 200px;
    }
    p {
        margin: 5px 0px;
    }
}
