@import "../mixins.module.scss";

.dropdownSection {
    @include sectionBoxStyles;
    @include mainContentSectionStyles;
    margin: 20px 0px;
    text-align: left;
    display: flex;
    justify-content: space-between;
    align-items: center;
    p {
        margin: 0px;
    }
}

@media screen and (max-width: 700px) {
    .dropdownSection {
        margin: 10px 0px;
    }

    .selectsWrapper {
        gap: 10px;
    }
}

@media print {
    .dropdownSection {
        box-shadow: unset;
        padding: 0px;
    }
    .printIcon {
        display: none;
        visibility: hidden;
    }
}
