@import "../../../mixins.module.scss";

@mixin textCell {
    margin: 20px 0px;
    p {
        margin: 10px 0px;
    }
    p:first-child {
        font-size: 0.9em;
    }
    p:nth-child(2) {
        font-weight: bold;
        min-height: 23px;
    }
}

.dropdownSection {
    @include sectionBoxStyles;
    @include mainContentSectionStyles;
    margin: 20px 0px;
    text-align: left;
    display: flex;
    justify-content: space-between;
    align-items: center;
    p {
        margin: 0px;
    }
}

.actionBar {
    display: flex;
    align-items: center;
}

.selectsWrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
}

.emptyReportArea {
    @include sectionBoxStyles;
    @include mainContentSectionStyles;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #adadad;
    flex-grow: 1;
}

.placeholder {
    span {
        margin-left: 10px;
        color: #1a1a1a;
    }
}

.reportArea {
    @include sectionBoxStyles;
    @include mainContentSectionStyles;
    overflow-y: scroll;
    color: #1a1a1a;
    height: calc(100vh - 250px);
    padding: 25px 45px;

    &::-webkit-scrollbar {
        width: 8px;
        background-color: rgba(0, 0, 0, 0.1);
    }

    &::-webkit-scrollbar-thumb {
        background-color: rgba(0, 0, 0, 0.1);
        border-radius: 3px;
        &:hover {
            background: rgba(0, 0, 0, 0.2);
        }
    }
}

.report {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.reportSection {
    margin-top: 40px;
    padding-bottom: 10px;
    text-align: left;
    border-bottom: 1px solid #e2e2e2;
    width: 100%;
    min-width: 1000px;
}

.reportSection:first-child {
    margin-top: 0px;
}

.reportHeader {
    font-weight: bold;
    text-align: left;
    display: inline-block;

    h3 {
        margin: 0px;
        display: inline-block;
    }

    .lineDecoration {
        height: 2px;
        background-color: #ec1027;
    }
}

.reportTextFlexWrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    text-align: left;

    .reportTextCell {
        @include textCell;
    }

    .reportTextCellTitle {
        display: flex;
        align-items: center;
        height: 20px;
        p {
            margin: 0px 20px 0px 0px;
        }
        svg {
            font-size: 0.9rem;
            color: #b3b3b3;
        }
    }
}

.reportTextParagraph {
    @include textCell;
    width: 100%;
    text-align: left;

    p:first-child {
        font-size: 0.9em;
        font-weight: bold;
    }
    p:nth-child(2) {
        font-weight: unset;
    }
    .reportTextCellTitle {
        display: flex;
        align-items: center;
        height: 20px;
        p {
            margin: 0px 20px 0px 0px;
        }
        svg {
            font-size: 0.9rem;
            color: #b3b3b3;
        }
    }
}

.tableContainer {
    margin-top: 20px;
}

.noDataAvailable {
    display: flex;
    justify-content: center;
    color: grey;
    align-items: center;
}

//Status Dropdown

.stateEditWrapper {
    height: 47px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.commentEditWrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
}

.noCommentText {
    color: grey;
    font-size: 0.8rem;
}

@media screen and (max-width: 700px) {
    .reportArea {
        max-height: calc(100vh - 234px);
    }
    .dropdownSection {
        margin: 10px 0px;
    }

    .selectsWrapper {
        gap: 10px;
    }
}

//Attachment section

.attachmentsContainer {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
}

.attachmentItem {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-right: 20px;
    button {
        margin-left: 5px;
    }
    p {
        margin: 5px 0px;
    }
}

.attachmentWrapper {
    background-color: #f8f8f8;
    border: 1px solid grey;
    border-radius: 20px;
    padding: 3px 20px;
    cursor: pointer;
    min-width: 120px;
    transition: background-color 200ms;

    &:hover {
        background-color: #ededed;
    }
}
